Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized
copying of this file, via any medium is strictly prohibited * Proprietary and
confidential * 2021 -->
<!--
File Name: feedAddDialog.vue
Description: This file contains UI components of add dialog from feed page and it includes a form
-->
<template>
  <er-dialog
    :title="
      `${actionToPerform === 'edit' ? $t('Comn_edit') : $t('Comn_create')} ${$t(
        'Stock_stock'
      )}`
    "
    top="25vh"
    width="80%"
    class="feed-add-dialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    @beforeClose="handleCloseDialog('close')"
    @open="handleDialogOpen"
    custom-class="feed-add-dialog-box"
  >
    <el-row>
      <el-row style="margin-bottom: 10px">
        <el-date-picker
          :clearable="false"
          size="mini"
          type="date"
          v-model="dateOfPurchase"
          :format="upm__getFormatDateString"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          @change="handelDate"
        ></el-date-picker>
      </el-row>

      <el-row>
        <er-data-tables
          type="white-header-table"
          :table-data="tableDataArr"
          :el-table-props="tableProps"
          :columns="getColumns(tableColumns)"
          :actionColumn="false"
          layout="table"
          uniqueId="feed_Add_Dialog"
        >
          <template v-slot:feed_name="{ row }">
            <er-select
              size="mini"
              v-model="row.data.resource_id"
              :placeholder="$t('select_feed')"
              @change="handleChangeFeedName(row.data)"
              :disabled="actionToPerform === 'edit' ? true : false"
            >
              <el-option
                v-for="(resource, resource_id) in getFeedObjResources"
                :label="`${resource.name}-${resource.feed_type}`"
                :value="resource_id"
                :key="resource_id"
              ></el-option>
            </er-select>
          </template>
          <template v-slot:number_of_units="{ row }">
            <el-input-number
              size="small"
              :min="1"
              :controls="false"
              v-model="row.data.number_of_units"
              @change="handleChangeInNoOfUnits(row.data)"
            ></el-input-number>
          </template>
          <template v-slot:unit_quantity="{ row }">
            <el-input-number
              size="small"
              :min="1"
              :controls="false"
              v-model="row.data.unit_quantity"
              @change="handleChangeInQuantity(row.data)"
            ></el-input-number>
          </template>

          <template v-slot:quantity_purchased="{ row }">{{
            ftm__digitPrecision(row.data.quantity_purchased, 2)
          }}</template>
          <template v-slot:units="{ row }">
            {{ getUnits(row.data.units) }}
          </template>
          <template v-slot:vendor_id="{ row }">
            <er-select
              v-model="row.data.vendor_id"
              size="mini"
              :placeholder="$t('Resource_select_vendors')"
            >
              <el-option
                v-for="vendor in getObjVendors"
                :label="vendor.name"
                :value="vendor._id"
                :key="vendor._id"
              ></el-option>
            </er-select>
          </template>
          <template v-slot:actions="{ row }">
            <template v-if="row.$index === tableDataLength - 1">
              <er-button
                icon="el-icon-plus"
                size="mini"
                @click="handleAddRow()"
                circle
              ></er-button>
            </template>
            <template v-else>
              <er-button
                icon="el-icon-minus"
                size="mini"
                circle
                @click="handleDeleteRow(row.$index)"
              ></er-button>
            </template>
          </template>
        </er-data-tables>
      </el-row>
    </el-row>
    <div slot="footer" class="dialog-footer">
      <div>
        <p class="footer__content" v-if="actionToPerform === 'add'">
          {{ $t("Stock_total_stock_abbr") }}
        </p>
        <!-- <p class="footer__content" v-else>
          {{ $t("Stock_edit_total_abbr") }}
        </p> -->
      </div>

      <div style="display: flex">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="handleSaveStock"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="handleCloseDialog('close')"
        ></er-button>
      </div>
    </div>
  </er-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Feed from "@/model/feed";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import notificationMixin from "@/mixins/notificationMixin";
export default {
  mixins: [
    datesHandlerMixin,
    filtersMixin,
    userPreferenceMixin,
    errorHandlerMixin,
    notificationMixin
  ],
  props: {
    feedStock: {
      default: function() {
        return [new Feed()];
      }
    },
    showDialog: {
      default: false
    },
    actionToPerform: {
      default: ""
    }
  },
  data: function() {
    return {
      tableDataArr: [new Feed()],
      dateOfPurchase: "",
      addFeedStock: {},
      total_purchased_stock: 0,
      stock_quantity: 0,
      loading: false
    };
  },

  computed: {
    ...mapGetters("resource", {
      getFeedObjResources: "getFeedObjResources",
      getObjVendors: "getObjVendors",
      getArrVendors: "getArrVendors",
      getFeedResources: "getFeedResources"
    }),
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZoneString: "getUserTimeZoneString"
    }),
    ...mapGetters("stock", {
      getAllFeeds: "getAllFeeds"
    }),
    pickerOptions() {
      return {
        disabledDate: function(time) {
          return time.getTime() > Date.now();
        }
      };
    },

    tableProps: function() {
      return {
        size: "small",
        defaultSort: {
          prop: "pond_title",
          order: "ascending"
        }
      };
    },
    tableDataLength: function() {
      return this.tableDataArr.length;
    },
    tableColumns() {
      return {
        feed_name: {
          prop: "feed_name",
          label: this.$t("Comn_feed_name"),
          minWidth: "100",
          align: "left",
          default: true
        },
        number_of_units: {
          prop: "number_of_units",
          label: this.$t("Stock_no_of_bags"),
          minWidth: "95",
          align: "left",
          default: true
        },
        unit_quantity: {
          prop: "unit_quantity",
          label: this.$t("Stock_bag_wgt"),
          minWidth: "100",
          align: "left",
          default: true
        },
        units: {
          prop: "units",
          label: this.$t("Comn_units"),
          minWidth: "70",
          align: "left",
          default: true
        },
        quantity_purchased: {
          prop: "quantity_purchased",
          label: this.$t("Stock_total_purchased"),
          minWidth: "75",
          align: "left",
          default: true
        },

        stock_quantity: {
          prop: "stock_quantity",
          label: this.$t("Stock_avail_stock"),
          minWidth: "65",
          align: "left",
          default: false
        },
        total_purchased: {
          prop: "total_purchased",
          label: this.$t("Comn_total_stock"),
          minWidth: "65",
          align: "left",
          default: false
        },

        vendor_id: {
          prop: "vendor_id",
          label: this.$t("Resource_vendor"),
          minWidth: "100",
          align: "left",
          default: true
        },
        actions: {
          prop: "actions",
          label: this.$t("Comn_actions"),
          minWidth: "50",
          align: "left",
          default: false
        }
      };
    },
    dialogVisible: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.handleCloseDialog("close");
      }
    }
  },
  methods: {
    ...mapActions("stock", {
      createStock: "createStock",
      updateFeedStock: "updateFeedStock",
      fetchIndividualStockSummary: "fetchIndividualStockSummary",
      fetchFeedStocks: "fetchFeedStocks"
    }),
    handelDate() {
      this.$emit('date-change', 'true');
    },
    getUnits(units) {
      const obj = {
        KG: "Kg",
        L: "L"
      };
      return obj[units];
    },

    getColumns(input) {
      const objArray = [];
      Object.keys(input).forEach((key) => objArray.push(input[key]));
      if (this.actionToPerform === "edit") {
        const editArrayCol = objArray.filter((item) => item.default === true);
        const object = editArrayCol.reduce(
          (acc, item) => Object.assign(acc, { [item.prop]: item }),
          {}
        );
        return object;
      } else {
        return input;
      }
    },

    handleDialogOpen() {
      this.tableDataArr = this.feedStock;
      this.total_purchased_stock = this.feedStock[0].total_purchased;
      this.stock_quantity = this.feedStock[0].stock_quantity;

      this.dateOfPurchase =
        this.actionToPerform === "add"
          ? this.dhm__formatTZ(
              this.dhm__dateUtilsLib.getCurrDateInGivenTZ(
                this.getUserTimeZoneString
              ),
              "yyyy-MM-dd"
            )
          : this.dhm__formatTZ(new Date(this.feedStock[0].date), "yyyy-MM-dd");
    },

    async handleSaveStock() {
      const feed = this.tableDataArr.reduce((acc, feed) => {
        feed.date = this.dateOfPurchase + "T00:00:00.000Z";
        if (this.actionToPerform === "edit") {
          feed.quantity_purchased = this.$lodash.cloneDeep(
            feed.total_purchased
          );
        }
        feed.location_id = this.getCurrUserLocation._id;
        acc.push(feed);
        return acc;
      }, []);

      if (this.actionToPerform === "edit" && this.tableDataArr.length === 1) {
        console.log(feed[0]);
        try {
          this.loading = true;
          await this.updateFeedStock(feed[0]);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Resource_updated_successfully"),
            duration: 5000,
            type: "success"
          });
          this.$emit("update-stock-feed", {
            resourceId: feed[0].resource_id,
            changeInStock: feed[0].quantity_purchased,
            id: feed[0]._id
          });
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
      if (this.tableDataArr.length >= 1 && this.actionToPerform === "add") {
        try {
          this.loading = true;
          await this.createStock(feed);
          this.$notify({
            title: this.$t("Usrs_success_msg"),
            message: this.$t("Resource_assigning_successfully"),
            duration: 5000,
            type: "success"
          });
          this.$emit("add-stock-feed");
        } catch (err) {
          this.ehm__errorMessages(err, true);
        } finally {
          this.loading = false;
        }
      }
      this.dialogVisible = false;
      this.$emit("close_dialog", 'save');
      // this.tableDataArr = [new Feed()];
    },
    handleCloseDialog(event) {
      this.tableDataArr = [new Feed()];
      this.$emit("close_dialog", event);
    },
    handleAddRow() {
      const initialfeed = this.getAllFeeds.filter(
        (feed) => feed._id === this.getFeedResources[0]._id
      );

      this.tableDataArr.push({
        resource_id: this.$lodash.cloneDeep(this.getFeedResources[0]._id),
        number_of_units: this.$lodash.cloneDeep(1),
        vendor_id: this.$lodash.cloneDeep(this.getArrVendors[0]._id),
        quantity_purchased: this.$lodash.cloneDeep(10),
        unit_quantity: this.$lodash.cloneDeep(10),
        units: this.$lodash.cloneDeep(this.getFeedResources[0].units),
        type: this.$lodash.cloneDeep(this.getFeedResources[0].type),
        stock_quantity: this.$lodash.cloneDeep(
          initialfeed.length ? initialfeed[0].stock_quantity : 0
        ),
        total_purchased: this.$lodash.cloneDeep(
          initialfeed.length ? initialfeed[0].stock_quantity + 10 : 10
        )
      });
      this.$emit('date-change', 'AddRow');
    },
    handleDeleteRow(rowIndexPos) {
      this.tableDataArr.splice(rowIndexPos, 1);
    },
    handleChangeInNoOfUnits(stockMedicineObj) {
      stockMedicineObj.quantity_purchased =
        stockMedicineObj.number_of_units * stockMedicineObj.unit_quantity;

      if (this.actionToPerform === "add") {
        const stock_quantity = stockMedicineObj.stock_quantity;
        stockMedicineObj.total_purchased =
          stock_quantity + stockMedicineObj.quantity_purchased;
      } else {
        stockMedicineObj.total_purchased = stockMedicineObj.quantity_purchased;
      }
    },
    handleChangeInQuantity(stockMedicineObj) {
      stockMedicineObj.quantity_purchased =
        stockMedicineObj.number_of_units * stockMedicineObj.unit_quantity;

      if (this.actionToPerform === "add") {
        const total_purchased_stock = stockMedicineObj.stock_quantity;
        stockMedicineObj.total_purchased =
          total_purchased_stock + stockMedicineObj.quantity_purchased;
      } else {
        stockMedicineObj.total_purchased = stockMedicineObj.quantity_purchased;
      }
    },
    handleChangeFeedName(feedname) {
      const result = this.getAllFeeds.filter(
        (item) => item._id === feedname.resource_id
      );
      if (result.length) {
        feedname.stock_quantity = result[0].stock_quantity;
        feedname.total_purchased = result[0].stock_quantity
          ? result[0].stock_quantity + feedname.quantity_purchased
          : feedname.quantity_purchased;
      } else {
        const result = this.getFeedResources.filter(
          (item) => item._id === feedname.resource_id
        );
        feedname.stock_quantity = 0;
        feedname.total_purchased = result[0].stock_quantity
          ? result[0].stock_quantity + feedname.quantity_purchased
          : feedname.quantity_purchased;
      }
    }
  }
};
</script>

<style lang="scss">
.feed-add-dialog {
  // width: 80% !important;
  .el-dialog__body {
    padding: 10px 20px;
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: stretch;
    .footer__content {
      opacity: 0.4;
      font-weight: bold;
      @include responsiveProperty(
        font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2
      );
    }
  }
  .el-input--small .el-input__inner {
    @include responsiveProperty(height, 28px, 32px, 40px);
    @include responsiveProperty(width, 100px, 110px, 120px);
  }
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 130px;
  }
}
.feed-add-dialog-box {
  border-radius: 10px;
  width: 80% !important;
}
</style>
