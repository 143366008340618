/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: feed.js
Description: This file has the model,functions  of feed object used in the pondlogs customer site
*/
export default class Feed {
  constructor() {
    this.available_stock = 0;
    this.last_purchased_quantity = 0;
    this.last_updated_date = "";
    this.no_of_items = undefined;
    this.per_item_size = undefined;
    this.feed_id = "";
    this.feed_name = "";
    this.total_price = 0;
    this.total_stock = 0;
    this.units = "";
    this.vendor_id = "";
  }
}
