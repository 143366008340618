<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feed.vue
Description:This file is no longer in use
-->
<template>
  <div class="stock-feed-view">
    <div>
      <component
        :is="`feedAddDialog`"
        :show-dialog="dialogVisible"
        :action-to-perform="actionToPerform"
        :feedStock="selectedFeedStock"
        @close_dialog="closeDialog"
        @date-change="handelDateChange"
        @update-stock-feed="handleUpdateStockFeed"
        @add-stock-feed="handleAddStockFeed"
      ></component>

      <feed-table
        :tabData="tabData"
        :expandRow="expandRow"
        :addFeedStock="addFeedStock"
        @handleOpenDialog="handleEditResource"
      ></feed-table>
    </div>

    <div class="bottom-container">
      <p v-html="$t('Medicines_abbr')"></p>
    </div>
  </div>
</template>

<script>
import feedActionBar from "@/components/stock/feedActionBar";
import feedTable from "@/components/stock/feedTable";
import feedAddDialog from "@/components/stock/feedAddDialog";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { feedActionBar, feedTable, feedAddDialog },
  props: ['tabData'],
  mixins: [errorHandlerMixin],
  data: function() {
    return {
      loading: false,
      selectedFeedStock: {},
      dialogVisible: false,
      actionToPerform: "",
      expandRow: undefined,
      addFeedStock: 0
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation"
    }),
    location: function() {
      return this.getCurrUserLocation;
    }
  },
mounted() {
  console.log("feed--", this.tabData);
},
  methods: {
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleEditResource(actionToPerform, resource) {
      this.selectedFeedStock = resource;
      this.actionToPerform = actionToPerform;
      this.dialogVisible = true;
    },
    closeDialog(event) {
      this.dialogVisible = false;
      if (this.actionToPerform === 'add' && event !== 'close') {
        this.mixPanelEventGenerator({ eventName: "Stock - Feed Table - Add Feed - Save" });
      }
      if (this.actionToPerform === 'edit' && event !== 'close') {
        this.mixPanelEventGenerator({ eventName: "Stock - Feed Table - Edit - Save" });
      }
    },
    handelDateChange(data) {
      if (this.actionToPerform === 'add') {
        if (data === 'AddRow') {
          this.mixPanelEventGenerator({ eventName: "Stock - Feed Table - Add Feed - Add Row" });
        } else {
          this.mixPanelEventGenerator({ eventName: "Stock - Feed Table - Add Feed - Date Filter" });
        }
      }
      if (this.actionToPerform === 'edit') {
        if (data === 'AddRow') {
          this.mixPanelEventGenerator({ eventName: "Stock - Feed Table - Edit - Add Row" });
        } else {
          this.mixPanelEventGenerator({ eventName: "Stock - Feed Table - Edit - Date Filter" });
        }
      }
    },
    async handleUpdateStockFeed(resource) {
      this.expandRow = {
        resourceId: resource.resourceId,
        changedStockfeedqty: resource.changeInStock,
        changedStockId: resource.id
      };
    },
    handleAddStockFeed() {
      this.addFeedStock++;
    }
  }
};
</script>

<style lang="scss">
.stock-feed-view {
  .bottom-container {
    opacity: 0.4;
    font-weight: bold;
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
  .input--search {
    padding: 0px;
    margin-right: 10px;
  }
}
</style>
