<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: feedActionBar.vue
Description:  This files contains UI components of feed Action bar and include add button to app feed inputs
-->
<template>
  <!-- <el-row type="flex" justify="end"> -->
  <el-col :span="3.3">
    <!-- <er-button
      btnType="save"
      :disabled="isReadOnly"
      @click="dialogVisibleStatus = true"
      size="mini"
      type="success"
    >
      <i class="el-icon-plus"></i>
      {{ $t("Stock_add_feed") }}
    </er-button>-->
    <er-action-button
      @action-btn-click="dialogVisibleStatus = true"
      :type="'solid'"
      :disabled="isReadOnly"
    >
      <template v-slot:icon>
        <img style="margin-top: 4px;" src="@/assets/button/add.svg" />
      </template>
      <template v-slot:label>
        <p style="text-transform: uppercase;">{{ $t("Stock_add_feed") }}</p>
      </template>
    </er-action-button>
  </el-col>
  <!-- </el-row> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("stock", {
      getStateOfDialogBox: "getStateOfDialogBox"
    }),
    ...mapGetters("user", {
      isReadOnly: "isReadOnly"
    }),
    dialogVisibleStatus: {
      get() {
        return this.getStateOfDialogBox;
      },
      set(value) {
        this.changeDialogBoxVisibleStatus(value);
      }
    }
  },

  beforeDestroy() {
    this.$store.dispatch("CANCEL_PENDING_REQUESTS");
  },
  methods: {
    ...mapActions("stock", {
      changeDialogBoxVisibleStatus: "changeDialogBoxVisibleStatus"
    })
  }
};
</script>

<style lang="scss" scoped></style>
